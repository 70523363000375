import { PublicFlight } from '@fllite-fe/shared/src/types';
import { diff } from '@fllite-fe/shared/src/utils/datetime';
import { capitalizeWords } from '@fllite-fe/shared/src/utils/fp';

export const destructData = (data: PublicFlight) => {
	const isInvitee = data.currentUserType === 'INVITEE';
	const isOwner = data.currentUserType === 'OWNER';
	const isParticipant = data.currentUserType === 'PARTICIPANT';
	const hasTrip = !!data.trip;
	const tripData = data.trip?.proposal;
	const tripLegs = data.trip?.proposal?.proposalLegs || [];

	const dateFrom = tripLegs[0].departureAt;
	const dateFromTimezone = tripLegs[0].departureAirport.timeZone;
	const dateToTimezone = tripLegs[tripLegs.length - 1].departureAirport.timeZone;
	const dateTo = tripLegs[tripLegs.length - 1].departureAt;
	const fromAndToDiff = Math.max(diff(dateTo, dateFrom, 'day'), 1);

	const firstLegOfTrip = data.trip?.proposal?.proposalLegs[0];
	const aircraft = firstLegOfTrip?.charterOperatorAircraft;
	const seatsLeft = data?.totalNumberOfSeats - data?.numberOfOccupiedSeats;

	const daysLeft: number = diff(dateFrom, new Date(), 'day');
	const monthsLeft = diff(dateFrom, new Date(), 'month');

	const isProposalApproved = data?.status === 'proposal_approved';

	const firstTripLeg = tripLegs[0];
	const flightType = data?.trip?.proposal?.flightType;

	const priceInclMargin = data?.trip?.proposal?.priceInclMargin;
	const targetPrice = priceInclMargin / data?.trip?.minimumNumberOfSeats;
	const whenUseTargetPriceYouSafePercent = Math.round(100 - (targetPrice / priceInclMargin) * 100);
	const targetReached = data.numberOfOccupiedSeats >= data?.trip?.minimumNumberOfSeats;

	const isBooked = data.status === 'paid';
	const isProposed = data.proposed && !isBooked;

	const isOneWay = data.trip.proposal.flightType.constantKey === 'FLIGHTTYPE_ONE_WAY';
	const isRoundTrip = data.trip.proposal.flightType.constantKey === 'FLIGHTTYPE_ROUND_TRIP';
	const isMultiLeg = data.trip.proposal.flightType.constantKey === 'FLIGHTTYPE_MULTI_LEG';

	const charterOperatorAircraft = data.trip.proposal.proposalLegs[0].charterOperatorAircraft;
	const charterOperatorAircraftInquiry = charterOperatorAircraft.aircraftType;
	const charterOperatorAircraftType =
		charterOperatorAircraftInquiry &&
		(charterOperatorAircraftInquiry?.name.includes('(')
			? capitalizeWords(
					charterOperatorAircraftInquiry.name.match(/\(([a-zA-Z0-9\s]+)\)/)[1].toLowerCase(),
				)
			: charterOperatorAircraftInquiry?.name);
	const charterOperatorAircraftImage = aircraft?.charterOperatorAircraftImages?.sort(
		(a, b) => a.imageOrder - b.imageOrder,
	)?.[0]?.url;

	return {
		aircraft,
		dateFrom,
		dateFromTimezone,
		dateTo,
		dateToTimezone,
		daysLeft,
		firstLegOfTrip,
		firstTripLeg,
		flightType,
		fromAndToDiff,
		hasTrip,
		isInvitee,
		isOwner,
		isParticipant,
		isProposalApproved,
		monthsLeft,
		seatsLeft,
		tripData,
		tripLegs,
		targetPrice,
		isBooked,
		isProposed,
		isOneWay,
		isRoundTrip,
		isMultiLeg,
		charterOperatorAircraftType,
		charterOperatorAircraftImage,
		whenUseTargetPriceYouSafePercent,
		targetReached,
	};
};
