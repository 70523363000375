import * as yup from 'yup';
import { ObjectSchema } from 'yup';

import { diff } from '@fllite-fe/shared/src/utils/datetime';

const NAME_IS_DIFFERENT = 'is-different';
export const MESSAGE_IS_DIFFERENT = 'Departure and destination must be different';

interface TestContextExtended {
	from: Array<{
		schema: ObjectSchema<any>;
		value: any;
	}>;
}

export const schema = yup.object().shape({
	trip: yup.object().shape({
		seats: yup.number().required().min(1),
		flightType: yup.object().required(),
		legs: yup.object().when('flightType', {
			is: (flightType) => flightType?.constantKey === 'FLIGHTTYPE_ONE_WAY',
			then: (schema) =>
				schema.shape({
					oneWay: yup
						.object()
						.shape({
							dateFrom: yup.date().required().nullable(),
							departure: yup.object().required().nullable(),
							destination: yup
								.object()
								.required()
								.nullable()
								.test({
									name: NAME_IS_DIFFERENT,
									message: MESSAGE_IS_DIFFERENT,
									test() {
										const functionContext = this as yup.TestContext & TestContextExtended;
										const currentDestination = functionContext.parent?.destination?.label;
										const currentDeparture = functionContext.parent?.departure?.label;
										return currentDestination !== currentDeparture;
									},
								}),
						})
						.required(),
				}),
			otherwise: (schema) =>
				schema.when('flightType', {
					is: (flightType) => flightType?.constantKey === 'FLIGHTTYPE_ROUND_TRIP',
					then: (schema) =>
						schema.shape({
							roundTrip: yup
								.object()
								.shape({
									dateTo: yup.date().required().nullable(),
									dateFrom: yup
										.date()
										.required()
										.nullable()
										.test({
											name: NAME_IS_DIFFERENT,
											message: 'Date from and date to must differ by at least two hours',
											test() {
												const functionContext = this as yup.TestContext & TestContextExtended;

												if (functionContext.parent?.dateTo) {
													const timeDifference: number = diff(
														functionContext.parent?.dateTo,
														functionContext.parent?.dateFrom,
														'hour',
														true,
													);
													return timeDifference >= 2;
												}

												return true;
											},
										}),
									departure: yup.object().required().nullable(),
									destination: yup
										.object()
										.required()
										.nullable()
										.test({
											name: NAME_IS_DIFFERENT,
											message: MESSAGE_IS_DIFFERENT,
											test() {
												const functionContext = this as yup.TestContext & TestContextExtended;
												const currentDestination = functionContext.parent?.destination?.label;
												const currentDeparture = functionContext.parent?.departure?.label;
												return currentDestination !== currentDeparture;
											},
										}),
								})
								.required(),
						}),
					otherwise: (schema) =>
						schema.when('flightType', {
							is: (flightType) => flightType?.constantKey === 'FLIGHTTYPE_MULTI_LEG',
							then: (schema) =>
								schema.shape({
									multiLeg: yup
										.array()
										.of(
											yup.object().shape({
												dateFrom: yup.date().required().nullable(),
												departure: yup.object().required().nullable(),
												destination: yup
													.object()
													.required()
													.nullable()
													.test({
														name: NAME_IS_DIFFERENT,
														message: MESSAGE_IS_DIFFERENT,
														test() {
															const functionContext = this as yup.TestContext & TestContextExtended;
															const currentDestination = functionContext.parent?.destination?.label;
															const currentDeparture = functionContext.parent?.departure?.label;
															return currentDestination !== currentDeparture;
														},
													}),
											}),
										)
										.required(),
								}),
						}),
				}),
		}),
	}),
});
