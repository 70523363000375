import styled from '@emotion/styled';
import { Box, Chip } from '@mui/material';

import { Button } from '@fllite-fe/shared/src/components/Button';
import { Icon } from '@fllite-fe/shared/src/components/Icon';
import { SeparatorThicker } from '@fllite-fe/shared/src/components/SeparatorThicker';
import { Paragraph, Text } from '@fllite-fe/shared/src/components/Text';

interface ContainerProps {
	isBooked?: boolean;
	isInShade: boolean;
	withClickAbleContent?: boolean;
}

export const Container = styled('a')<ContainerProps>`
	position: relative;
	background: ${({
		isBooked,
		theme,
	}) => `radial-gradient(circle at 100% 198px, rgba(204, 0, 0, 0) 12px, ${
		isBooked ? '#0056be' : '#fff'
	} 13px),
		radial-gradient(circle at 0 198px, rgba(204, 0, 0, 0) 12px, ${
			isBooked ? '#0056be' : '#fff'
		} 13px)`};
	background-position:
		top right,
		top left;
	background-size: 51% 100%;
	background-repeat: no-repeat;
	overflow: hidden;
	border-radius: ${({ theme }) => theme.radius.boxRadius};

	:before {
		content: ' ';
		display: ${({ withClickAbleContent }) => (withClickAbleContent ? 'none' : 'block')};
		transition: all 0.2s ease-in-out;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: ${({ isInShade }) => (isInShade ? 0.5 : 0)};
		background: #fff;
		z-index: 15;
	}

	${({ theme }) => theme.breakpoints.down('sm')} {
		width: calc(100vw - 60px);
	}

	${({ theme }) => theme.breakpoints.up('md')} {
		display: grid;
		grid-row: span 5;
		gap: 8px;
		grid-template-rows: subgrid;
	}
`;

export const ContainerLikeNotLink = Container.withComponent('div');

export const BaseWrapper = styled('div')``;
export const ExtendedWrapper = styled('div')``;

export const Image = styled('img')`
	width: 100%;
	height: 170px;
	border-radius: ${({ theme }) => theme.radius.smallBoxRadius};
	object-fit: cover;
`;

export const ImageWrapper = styled('div')`
	padding: 8px 8px 0;
	object-fit: cover;
	position: relative;
	display: inline block;
`;

export const BookedOrProposed = styled(Box)`
	position: absolute;
	left: 16px;
	top: 15px;
`;

export const BookedOrProposedContent = styled(Box)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	white-space: nowrap;
	float: right;
	padding: 4px 12px;
	border-radius: ${({ theme }) => theme.radius.smallBoxRadius};
	background: ${({ theme }) => theme.colors.grayLighter};
`;

export const InfoCircle = styled('div')<{ isProposed: boolean }>`
	background: ${({ theme, isProposed }) =>
		isProposed ? theme.colors.primary : theme.colors.green};
	border-radius: 50%;
	height: 9px;
	width: 9px;
	margin-right: 8px;
	box-shadow: 0 0 0 1.5px white;
`;

export const AircraftText = styled(Text)`
	text-align: center;
`;

export const Col = styled('div')`
	position: relative;
	display: flex;
	align-items: center;
	flex-direction: column;
`;

export const InfoBox = styled('div')<{ isBooked: boolean }>`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: rgba(140, 192, 255, 0.3);
	border-radius: 4px;
	color: ${({ isBooked }) => (isBooked ? '#fff' : '#263238')};
	height: 100%;
`;

export const StyledIcon = styled(Icon)`
	height: 16px;
	margin-bottom: 5px;
	display: inline-flex;
`;

export const StyledSeparatorThicker = styled(SeparatorThicker)`
	width: calc(100% - 24px);
	margin-left: auto;
	margin-right: auto;
	position: absolute;
	top: 50%;
	left: 12px;
	z-index: 0;
`;

export const TripName = styled(Text)`
	text-align: center;
	margin: 0 8px;
`;

export const Description = styled(Paragraph)`
	text-align: center;
	margin: 0 8px;
`;

export const SeparatorWrapper = styled('div')`
	position: relative;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-top: 1px;
`;

export const HalfCircle = styled('div')<{ isOnLeftSide?: boolean; customBackgroundColor?: string }>`
	z-index: 1;
	width: 12px;
	height: 24px;
`;

export const GreenBulletWrapper = styled('div')`
	z-index: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 14px;
	padding: 4px 12px;
	height: 24px;
	background: ${({ theme }) => theme.gradients.green};
	color: white;
`;

export const GreenBulletText = styled('span')`
	font-size: 10px;
	line-height: 16px;
	font-weight: 700;
	letter-spacing: 1px;
	color: white;
	text-transform: uppercase;
`;

export const StatusText = styled('span')`
	font-size: 10px;
	line-height: 16px;
	font-weight: 600;
	color: ${({ theme }) => theme.gradients.green};
	text-transform: uppercase;
`;

export const LargeText = styled('span')<{ isBooked: boolean }>`
	font-size: 36px;
	line-height: 40px;
	font-weight: 400;
	color: ${({ theme, isBooked }) => (isBooked ? '#fff' : theme.colors.brownDark)};
	text-transform: uppercase;
`;

interface DescriptionBoxProps {
	minDescriptionHeight?: number;
}
export const DestinationsBox = styled('div')<DescriptionBoxProps>`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0 16px;
	align-items: center;
	& > div {
		max-width: 40%;
	}
	${({ minDescriptionHeight }) =>
		minDescriptionHeight ? `min-height: ${minDescriptionHeight}px;` : ''}
`;

export const TextBox = styled(Box)<{ justifyContent?: 'flex-start' | 'flex-end' }>`
	display: flex;
	flex-direction: column;
	justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
	align-items: ${({ justifyContent }) => (justifyContent ? justifyContent : 'flex-start')};
`;

export const IconWrapper = styled('div')`
	padding: 0 16px;
	margin-left: -28px;
`;

export const RadiusPlaceholderBox = styled('div')`
	margin: 15px 5px;
	width: 91px;
	border-radius: ${({ theme }) => theme.radius.smallBoxRadius};
`;

export const PaddingBox = styled('div')`
	padding: 0 20px;
`;

export const StyledButton = styled(Button)<{ isBooked?: boolean }>`
	width: 100%;
	gap: 4px;
	display: flex;
	flex-direction: row;
	${({ isBooked }) => isBooked && `color: white`}
`;

export const BoxesRow = styled('div')`
	width: 100%;
	padding: 0px 15px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
`;

export const StyledChip = styled(Chip)`
	position: absolute;
	top: -15px;
	right: 0;
	font-size: 10px;
`;
