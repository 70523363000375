import styled from '@emotion/styled';

import { Icon } from '@fllite-fe/shared/src/components/Icon';

const VIEW_ALL_WIDTH = '100px';

export const Section = styled('div')<{ hideJumpOnAFlightSection?: boolean }>`
	background-color: transparent;
	margin-top: ${({ hideJumpOnAFlightSection }) => (hideJumpOnAFlightSection ? '0' : '32px')};
	margin-bottom: 40px;
	max-width: 100vw;
	overflow-x: hidden;
	transition: margin-top 0.3s ease-in-out;
`;

export const Container = styled('div')`
	margin: auto;
`;

export const ControlLine = styled('div')`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	margin-bottom: 40px;

	${({ theme }) => theme.breakpoints.up('sm')} {
		flex-direction: row;
		justify-content: center;
		margin-bottom: 49px;
	}
`;

export const Filler = styled('div')`
	width: ${VIEW_ALL_WIDTH};
`;

export const BasicLink = styled('a')<{ isHidden?: boolean }>`
	width: ${VIEW_ALL_WIDTH};
	font-size: 14px;
	font-weight: 500;
	color: ${({ theme }) => theme.colors.primary};
	text-decoration: none;
	padding-left: 10px;
	padding-right: 10px;
	white-space: nowrap;
	display: none;

	${({ theme }) => theme.breakpoints.up('sm')} {
		display: block;
		visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
	}
`;

export const StyledIcon = styled(Icon)`
	margin-left: 10px;
`;

export const FiltersContainer = styled('div')`
	display: grid;
	grid-template-columns: repeat(2, calc(50% - 4px));
	grid-column-gap: 10px;
	grid-row-gap: 17px;
	width: 100%;

	${({ theme }) => theme.breakpoints.up('sm')} {
		display: inline-flex;
		flex-wrap: wrap;
		align-items: center;
		justify-content: center;
		gap: 12px;
		width: auto;
	}
`;

export const SelectInputContainer = styled('div')`
	width: 100%;
	grid-column: span 2;

	${({ theme }) => theme.breakpoints.up('sm')} {
		width: 224px;
	}
`;

export const FlightsContainer = styled('div')<{ numberOfFlights: number }>`
	display: grid;
	grid-template-columns: repeat(3, minmax(200px, 400px));
	gap: 16px;
	${({ theme }) => theme.breakpoints.down('lg')} {
		grid-template-columns: repeat(2, minmax(200px, 400px));
	}
	${({ theme }) => theme.breakpoints.down('md')} {
		grid-template-columns: repeat(1, minmax(200px, 100vw));
	}
`;

export const MoreLinkContainer = styled('div')`
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-bottom: 25px;
	${({ theme }) => theme.breakpoints.up('md')} {
		justify-content: flex-end;
	}
`;

export const MoreLinkIcon = styled(Icon)`
	margin-left: 10px;
`;
