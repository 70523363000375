import { FC } from 'react';

import { destructData } from 'components/PageJumpOnAFlight/JumpOnFlightCardInline/utils/destructData';

import { SharedTripBusinessCase } from '@fllite-fe/shared/src/types';

import { GreenBulletText, GreenBulletWrapper } from './FlightCard.styles';

interface FlightCardProps {
	data: SharedTripBusinessCase;
}

export const TripTypeText: FC<FlightCardProps> = ({ data }) => {
	const { isOneWay, isRoundTrip, isMultiLeg } = destructData(data);

	return (
		<GreenBulletWrapper>
			<GreenBulletText>
				{isOneWay && 'One way'}
				{isRoundTrip && 'Round trip'}
				{isMultiLeg && 'Multi leg'}
			</GreenBulletText>
		</GreenBulletWrapper>
	);
};
