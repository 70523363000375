import { FC } from 'react';

import { AirplanemodeActiveOutlined, ChairOutlined, East } from '@mui/icons-material';
import { Box, Grid, Stack, Tooltip, Typography } from '@mui/material';
import { destructData } from 'components/PageJumpOnAFlight/JumpOnFlightCardInline/utils/destructData';
import Link from 'next/link';

import { TextWithMaxWidth } from '@fllite-fe/shared/src/components/LegsInfo/LegsInfoCardSimple';
import { PricePerSeat } from '@fllite-fe/shared/src/components/ProposalCard/PricePerSeat';
import { PriceTaxes } from '@fllite-fe/shared/src/components/ProposalCard/PriceTaxes';
import { Text } from '@fllite-fe/shared/src/components/Text';
import { BusinessCase, SharedTripBusinessCase } from '@fllite-fe/shared/src/types';
import { formatPrice } from '@fllite-fe/shared/src/utils/formatters';
import { capitalizeWords } from '@fllite-fe/shared/src/utils/fp';
import { getBCStatus } from '@fllite-fe/shared/src/utils/getBCStatus';
import { getPricePerSeat } from '@fllite-fe/shared/src/utils/getPricePerSeat';

import { DateBox } from './DateBox';
import {
	BaseWrapper,
	BookedOrProposed,
	BookedOrProposedContent,
	Container,
	DestinationsBox,
	ExtendedWrapper,
	HalfCircle,
	IconWrapper,
	Image,
	ImageWrapper,
	InfoBox,
	InfoCircle,
	LargeText,
	SeparatorWrapper,
	StatusText,
	StyledButton,
	StyledChip,
	StyledIcon,
	StyledSeparatorThicker,
	TextBox,
} from './FlightCard.styles';
import { TripTypeText } from './TripTypeText';

interface FlightCardProps {
	data: SharedTripBusinessCase;
	isInShade?: boolean;
	showAircraftImage?: boolean;
	numberOfSeats?: number;
}

export const DATE_FORMAT = 'MMM D, YYYY';

export const FlightCard: FC<FlightCardProps> = ({
	data,
	isInShade = false,
	showAircraftImage = false,
	numberOfSeats = 1,
}) => {
	// we need if we have data and if in data is all what we need
	if (!data || !data?.trip?.proposal?.flightType?.constantKey) {
		return null;
	}

	const { isProposedTripInWaitingRoom } = getBCStatus(data);

	const {
		seatsLeft,
		dateFrom,
		dateTo,
		dateToTimezone,
		dateFromTimezone,
		targetReached,
		isBooked,
		isProposed,
		tripLegs,
		isOneWay,
		charterOperatorAircraftType,
		charterOperatorAircraftImage,
	} = destructData(data);

	const depAirport = tripLegs[0].departureAirport;
	const destAirport = tripLegs[0].destinationAirport;

	const primaryTextColor = isBooked ? 'white' : 'primary';

	const price = data.proposed
		? getPricePerSeat(data, true, numberOfSeats, true)
		: getPricePerSeat(data, isProposedTripInWaitingRoom, numberOfSeats) * numberOfSeats;
	const sale = Math.round(100 - (price / data.trip.proposal.priceInclMargin) * 100);

	return (
		<Link href="/detail/[id]" as={`/detail/${data.id}`} passHref legacyBehavior>
			<Container isBooked={isBooked} isInShade={isInShade}>
				<BaseWrapper>
					<ImageWrapper>
						<Image
							src={
								showAircraftImage
									? charterOperatorAircraftImage ?? '/assets/aircraft-placeholder.webp'
									: data.customImageUrl ?? (data as BusinessCase).image?.url
							}
							alt="image"
						/>
					</ImageWrapper>
					<BookedOrProposed>
						<Stack direction="row" spacing={1}>
							{(isBooked || isProposed) && (
								<Tooltip
									arrow
									title={
										<Text color="white">
											{isBooked &&
												'This flight has been fully approved, so it’s guaranteed to fly if you join.'}
											{isProposed &&
												'This is a proposed flight which you can join, and can be booked by the flight owner when you are all happy with the flight set up.'}
										</Text>
									}
								>
									<BookedOrProposedContent>
										<InfoCircle isProposed={isProposed} />
										<StatusText>
											{isBooked && 'Trip Approved'}
											{isProposed && 'Trip Proposed'}
										</StatusText>
									</BookedOrProposedContent>
								</Tooltip>
							)}
							<BookedOrProposedContent>
								<Stack direction="row" spacing={1}>
									<AirplanemodeActiveOutlined color="primary" sx={{ fontSize: '16px' }} />
									<StatusText>{charterOperatorAircraftType}</StatusText>
								</Stack>
							</BookedOrProposedContent>
						</Stack>
					</BookedOrProposed>

					<SeparatorWrapper>
						<StyledSeparatorThicker dashed />
						<HalfCircle isOnLeftSide />
						<TripTypeText data={data} />
						<HalfCircle />
					</SeparatorWrapper>
					{data.tripName && (
						<Stack justifyContent="center" alignItems="center" my={1}>
							<Typography fontWeight={600} fontSize={12} color="primary">
								{data.tripName}
							</Typography>
						</Stack>
					)}
					<DestinationsBox>
						<Stack direction="row" spacing={1} width="148px">
							<TextBox>
								<LargeText isBooked={isBooked}>{depAirport.airportCode}</LargeText>
								<Text subtext brownDark={isProposed} white={isBooked}>
									<TextWithMaxWidth>
										{capitalizeWords(depAirport.city)}
										{depAirport.state?.code && `, ${depAirport.state.code}`}
									</TextWithMaxWidth>
								</Text>
							</TextBox>
						</Stack>
						<IconWrapper>
							<FlightTypeIcon data={data} />
						</IconWrapper>
						<TextBox width="120px" justifyContent="flex-end">
							<LargeText isBooked={isBooked}>{destAirport.airportCode}</LargeText>
							<Text subtext brownDark={isProposed} white={isBooked}>
								<TextWithMaxWidth>
									{capitalizeWords(destAirport.city)}
									{destAirport.state?.code && `, ${destAirport.state?.code}`}
								</TextWithMaxWidth>
							</Text>
						</TextBox>
					</DestinationsBox>
				</BaseWrapper>
				<Stack direction="row" justifyContent="space-between" padding="8px 16px 12px">
					<DateBox
						title="Departure"
						date={dateFrom}
						timezone={dateFromTimezone}
						isBooked={isBooked}
					/>

					{!isOneWay && (
						<DateBox
							title="Return"
							date={dateTo}
							timezone={dateToTimezone}
							isBooked={isBooked}
							fixed
							alignRight
						/>
					)}
				</Stack>
				<ExtendedWrapper>
					<Grid container spacing={1} padding="4px 16px 16px">
						<Grid item xs={12}>
							<Stack direction="row" spacing="4px" alignItems="center" mb="24px">
								<ChairOutlined color="success" sx={{ fontSize: '16px' }} />
								<Typography fontWeight={400} fontSize={12} color={isBooked ? 'white' : '#353535'}>
									<strong>{seatsLeft}</strong> seat{seatsLeft > 1 && 's'} available
								</Typography>
							</Stack>
						</Grid>
						<Grid item xs={12}>
							<InfoBox isBooked={isBooked}>
								<Stack
									direction="row"
									justifyContent="space-between"
									width="100%"
									spacing={2}
									alignItems="center"
									flexWrap={{ xs: 'wrap', mini: 'nowrap' }}
								>
									<Stack spacing="4px" minWidth="120px" padding="10px 16px" textAlign="left">
										<Typography fontSize={12}>
											{data.proposed && !targetReached ? 'Target Price' : 'Current Price'}
										</Typography>
										<Stack direction="row" spacing="4px" alignItems="center">
											<ChairOutlined color="success" sx={{ fontSize: '16px' }} />
											<Typography fontWeight={600} fontSize={12} color={primaryTextColor}>
												<strong>{numberOfSeats}</strong> seat{numberOfSeats > 1 && 's'}
											</Typography>
										</Stack>
									</Stack>

									<Stack
										alignItems="flex-end"
										justifyContent="flex-end"
										padding="8px 16px"
										height="100%"
										width="100%"
									>
										<Stack direction="column" alignItems="flex-end" justifyContent="flex-end">
											<Typography fontSize={24} fontWeight={600} color={primaryTextColor}>
												{formatPrice(price, 0)}
											</Typography>
											<PriceTaxes
												numberOfSeats={numberOfSeats}
												numberOfLegs={tripLegs.length ?? 1}
												price={price}
												proposalLegs={tripLegs}
											/>
										</Stack>
										{numberOfSeats > 1 && (
											<Box>
												<PricePerSeat
													price={getPricePerSeat(data, isProposedTripInWaitingRoom, numberOfSeats)}
													inverted={isBooked}
												/>
											</Box>
										)}
									</Stack>
								</Stack>
								<StyledChip variant="successBold" label={`${sale}% OFF`} />
							</InfoBox>
						</Grid>
					</Grid>
				</ExtendedWrapper>
				<Box px="16px" mb="24px">
					<StyledButton text isBooked={isBooked}>
						See details <East style={{ fontSize: '16px' }} />
					</StyledButton>
				</Box>
			</Container>
		</Link>
	);
};

interface FlightTypeIconProps {
	data: SharedTripBusinessCase;
}

export const FlightTypeIcon: FC<FlightTypeIconProps> = ({ data }) => {
	const { isBooked, isProposed, isRoundTrip } = destructData(data);
	return (
		<>
			{isProposed &&
				(isRoundTrip ? (
					<StyledIcon type="aircraft-multiple-dark-blue" />
				) : (
					<StyledIcon type="aircraft-dark-blue" />
				))}
			{isBooked &&
				(isRoundTrip ? (
					<StyledIcon type="aircraft-multiple-white" />
				) : (
					<StyledIcon type="aircraft-white-2" />
				))}
		</>
	);
};
